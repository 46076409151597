.legal_hero h1{
    font-family: 'Spectral';
    color: white;
    font-size: 42px;
    margin-top: 80px;
    font-weight: bold;
    padding-top: 30px;
    text-align: center;
    border-radius: 10px;
    width: auto;
    height: 115px;
    border: 1px solid transparent;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    box-shadow: 6px 10px 14px 0px rgba(166,152,150,0.75);
}
.section{
    width: 300px;
    height: 410px;
    border: 1px solid black;
    margin-top: 100px;
    /* background-color: darkblue; */
    background-color: rgba(0, 7, 61)
   
}
.section button{
    width: 298px;
    border-radius: 0px;
    background-color: transparent;
    height: 45px;
    border-color: transparent;
    color: white;
    font-size: 1.2rem;
    text-align: left;
    padding-left: 25px;
    font-family: 'Spectral';
    transition: all 0.5s;
    gap: 2rem;
    
}
.section button:hover, .section button:focus{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
}
#button_active{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
}
.side_section h2{
    margin-top: 100px;
    color: darkblue;
    font-family: 'Spectral';
    font-weight: bold;
}
.side_section h5{
    margin-top: 30px;
    color: darkblue;
    font-family: 'Spectral';
}
.side_section p{
    color: blue;  
    font-family: 'Spectral';
    margin-top: 30px;
    font-size: 1rem;
    line-height: 2rem;
}
 



/* @media only screen and (max-width: 640px){
    .legal_hero h1{
        display: none;
    }
    .section{
        display: none;
    }
    .side_section h2{
        margin-top: 50px;
    }
} */
/* @media only screen and (max-width: 768px){
    .legal_hero h1{
        display: none;
    }
    .section{
        display: none;
    }
    .side_section h2{
        margin-top: 50px;
    }
} */