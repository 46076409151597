/* .about_hero{
  

} */
.about_hero h1{
    font-family: 'Spectral', serif;
  
}

.about_product h1{
    /* margin-top: 60px; */
    font-size: 1.8rem;
    font-family: 'Spectral', serif;
    color: white;
    margin-bottom: 30px;
   
}
.product{
    padding: 36px;
}

.circle-product{
    width: 80px;
    height: 80px;
    background-color: #3e19ff;
    border-radius: 50%;
}

.about_product h3{
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Spectral', serif;
  
  
}

.product:hover  h3{
   
    background: linear-gradient( to left, #25CBD3 50%, #fff 50%);
    background-size: 200%, 100%;
    background-position: 100%;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    transition: .5s;
    cursor: pointer;
   
    
}
.text-yellow{
    color:white;
}
.product:hover  .text-yellow{
   
   color: #25CBD3;
   background: none;
   
    
}


.about_product p{
    color: white;  
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;  
}
.about_value h1{
    margin-top: 60px;
  
    font-family: 'Spectral', serif;
   
    margin-bottom: 30px;
   
}
.about_value h3{
    color: darkblue;
    font-size: 1.2rem;
    font-family: 'Spectral', serif;
    /* text-align: center;  */
   
    
}
.about_value p{
    color: black;  
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;  
    text-align: justify;
    
}



.view-btn{
    margin-top: 20px;
    border-radius: 50px;
    max-width: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 10px 20px;
    color: #ffffff;
    background: linear-gradient(45deg, #3e19ff 0%, #903eff 100%);
    box-shadow: 0px 7px 13px 0px rgb(153 99 255 / 59%);
    transition: all 0.3s linear;
}

.view-btn:hover{
    color: #ffffff;
    background: linear-gradient(45deg, #903eff 0%, #f1f0f7 100%);
    transition: all 0.3s linear;
}
.main-card{
    background: linear-gradient(45deg, #e7e7ea 10%, #903eff 10%);
    border-radius: 10px 10px 10px 10px;
    padding: 0px 0px 34px 0px;

}
.img-w-img{
    position: relative;
    
    border-radius: 10px ;
}
.card-content{
    padding: 10px;
}
.anchor-text{
    text-decoration: none;
    color:#25CBD3;
}
.anchor-text:hover{
    text-decoration: none;
    color:#25CBD3;
}