.exchange__section{
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
    height: 100vh;
}

.illustration .two{
    position: absolute;
    top: 2%;
    right: 2%;
    z-index: 1;
    background-size: cover;
    max-width: 40%;
}
.illustration .one{
    position: absolute;
    top: 10%;
    left: -4%;
    z-index: 1;
    max-width: 20%;
}

.illustration .three{
 position: absolute;
 left: -14%;
 bottom: -5%;
    z-index: 1;
} 
.hero-area__exchange{
    position: relative;
    z-index: 1;
}
.banner-content__exchange{
max-width: 500px;
}

.banner-content__exchange .subtitle{
    color: #6342ff;
}
.banner-content__exchange .head__exchange{
color: #33276a;
font-weight: 700;
font-size: 60px;
}

.text__exchange{
    color: #4f457c;
    font-size: 1.5rem;
    line-height: 2rem;
    padding-top: 20px;
}
.exchange{
    
    background-color: #fff;
    border-radius: 20px;
    box-shadow: -1.361px 25.964px 40px 0px rgb(104 67 255 / 20%);
    padding: 40px 40px 40px 40px;
    background-repeat: no-repeat;
    background-size: 100% 144px, cover;
    background-position: left 0px top 0px;
    z-index: 9999;
}
.head__heading-exchange{
    font-size: 28px;
    font-family: "Josefin Sans";
    color: white;
    font-weight: 700;
    line-height: 1.357;
    text-align: center;
    padding-bottom: 40px;
}

.exchange-box{
    padding-top: 20px;
}
.text-selector{
 font-size: 18px;
    font-family: "Open Sans", sans-serif;
    color: #6342ff;
    line-height: 1.556;
}

/* .btc__img-coin{
    margin-right: 5px;
    max-width: 100%;
} */
/* .form-group input{
    border: 1px solid #d2c8ec;
    background-color: white;
    border-radius: 30px;
    padding: 10px 20px 10px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    color: #4938a2;
} */
.second__exchange-section h3{
    margin-top: 20px;
}
.second__exchange-section .row {
    margin: 0 !important;
}

.offer{
    padding: 70px;
}
.offer-head{
    font-size: 24px;
    font-family: "Josefin Sans";
    color: #33276a;
    font-weight: 700;
    line-height: 1.3;
    padding-top: 25px;
    text-align: center;
}
.icon-bg{
    border-radius: 50%;
    background-color: #C4D8E9;

    box-shadow: 2.847px 10.625px 54px 0px rgba(144, 103, 233, 0.25);
    width: 120px;
    height: 120px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media only screen and (max-width: 640px) {
    .text__exchange{
      
        font-size: 1rem;    
    }
    
    .second__exchange-section h3{
        margin-top: 450px;
    }
    .exchange{
        min-width: fit-content;
    }
    .banner-content__exchange .head__exchange{
       
        font-size: 30px;
        }
        .text__exchange{
          
            font-size: 1rem;
            line-height: 1.8rem;
            padding-top: 5px;
        }
        .second__exchange-section .row {
            margin: 0 !important;
        }
        
        .banner-content__exchange {
            max-width: 445px;
        }
        .hero-area__exchange{
            position: absolute;
            z-index: 1;
        }
        .banner-content__exchange .subtitle {
            font-size: 1rem;
            margin-top: 45px;
        }
}


@media only screen and (max-width: 480px) {
    .text__exchange{
      
        font-size: 1rem;    
    }
    .banner-content__exchange .subtitle {
        
        margin-top: 120px;
    }
    .second__exchange-section h3{
        margin-top: px !important;
    }
    .About-us__exchange .about-image{
        display: none;
    }
    .exchange{
        min-width: fit-content;
        margin-left: 0px !important;
    }
    .banner-content__exchange .head__exchange{
       
        font-size: 20px !important;
        }
        .text__exchange{
          
            font-size: 0.7rem;
            line-height: 1.8rem;
            padding-top: 5px;
        }
        .second__exchange-section .row {
            margin: 0 !important;
        }
        .banner-content__exchange {
            max-width: 200px;
        }
}





@media only screen and (max-width: 992px) {
    .banner-content__exchange .subtitle{
      font-size: 1rem;
    }
    .banner-content__exchange .head__exchange{
    color: #33276a;
    font-weight: 700;
    font-size: 30px;
    }
    .text__exchange{
      
        font-size: 1.1rem;
        line-height: 1.8rem;
        padding-top: 15px;
    }
    .second__exchange-section .row {
        margin: 0 !important;
    }
    
}




/* =================media style ======================= */
/* @media only screen and (max-width: 1200px) {
    .banner-content__exchange .subtitle{
        color: #6342ff;
    }
    .banner-content__exchange .head__exchange{
    color: #33276a;
    font-weight: 700;
    font-size: 36px;
    }
    .text__exchange{
      
        font-size: 1.2rem;
        line-height: 1.8rem;
        padding-top: 15px;
    }
} */
@media only screen and (max-width: 768px){
    .exchange {
        margin-left: 83px;
        width: 114%;
    }
    .illustration .three{
        left: -14%;
        bottom: -5%;
           z-index: 1;
           position: inherit;
       } 
       .hero-area__exchange{
        position: relative;
        z-index: 1;
        margin-top: -260px;
    }
    .exchange__section{
       
        height: 53vh;
    }
    .second__exchange-section h3 {
        margin-top: 85px;
    }
    .offer-head {
        font-size: 26px;
    }
}



@media only screen and (max-width: 1024px){
    .exchange {
        margin-left: 83px;
        width: 114%;
    }
    .illustration .three{
        left: -14%;
        bottom: -5%;
           z-index: 1;
           position: inherit;
       } 
       .hero-area__exchange{
        position: relative;
        z-index: 1;
        margin-top: -260px;
    }
    .exchange__section{
       
        height: 100vh;
    }
    .banner-content__exchange .head__exchange {
       
        font-size: 30px;
    }
    .text__exchange {
        font-size: 1.4rem;
    }
    .second__exchange-section h3 {
        margin-top: 220px;
    }
}