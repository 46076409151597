.bug_hero h6{
  color: white;
  font-family: 'Spectral', serif;
  margin-top: 150px;
  font-size: 0.9rem;
}
.bug_hero h2{
    color: white;
    font-family: 'Spectral', serif;
    margin-top: 10px;
    font-size: 3rem;
  }
.bug_hero button{
    border-radius: 10px;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
  }
.bug_hero button:hover{
        color: aqua !important;
  }
.bug1 h1{
    color: aqua;
    font-family: 'Spectral', serif;
    margin-top: 50px;
    font-size: 2.1rem;
    margin-bottom: 40px;
}
.bug1 span{
    color: white;
    font-family: 'Spectral', serif;
    font-size: 1.3rem;
    font-weight: 200;
}
.bug1 p{
    color: white;
    font-family: 'Open Sans', sans-serif;
    margin-top: 40px;
    font-size: 1rem;
}
.bug2 h1{
    color: aqua;
    font-family: 'Spectral', serif;
    margin-top: 50px;
    font-size: 2.1rem;
    margin-bottom: 30px;
}
.bug2 span{
    color: white;
    font-family: 'Spectral', serif;
    font-size: 1.3rem;
    
}
.bug2 li{
  color: white;
  font-size: 1.1rem;
  padding-top: 15px;
  font-family: 'Open Sans', sans-serif;
}
.bug2 ul{
  list-style: disc;
  padding-left: 22px;
  font-family: 'Spectral', serif;

}
.bug2 p{
    color: white;
    font-family: 'Spectral', serif;
    font-size: 1.3rem;
}
.bug3 h1{
  color: aqua;
  font-family: 'Spectral', serif;
  margin-top: 50px;
  font-size: 2.1rem;
  margin-bottom: 40px;
}
.bug3 strong{
  color: green;
  font-family: 'Spectral', serif;
  font-size: 1.3rem;
  font-weight: bold;
}
.bug3 p{
  color: white;
  font-family: 'Spectral', serif;
  font-size: 1.3rem;
}
.bug3 span{
  color: white;
  font-family: 'Spectral', serif;
  font-size: 1.3rem;
  font-weight: 200;
}

















@media only screen and (max-width: 640px){
  .bug_hero h6{
    margin-top: 30px;
  
  }
}
@media only screen and (max-width: 768px){
  .bug_hero h6{
    margin-top: 30px;
  }
}