.faq_hero span{
    color: darkblue;
    font-family: 'Spectral', serif;
    margin-top: 100px;
    font-size: 2rem;
    margin-bottom: 50px;
}
.faq_box {
    height: 58px;
    width: 100%;
    border-top: 1px solid darkblue;
    cursor: pointer;
}
.left-icons svg{
    color: darkblue;
    font-size: 30px;
    text-align: center;
    margin: 10px 12px 0px;
}
.right-icons svg{
    color: darkblue;
    font-size: 25px;
    margin: 10px 264px 0px;
    text-align: right;
    width: 70%;
}

.middle-text h3{
    padding-top: 12px;
    margin: 0 4px;
    font-size: 15px;
    font-weight: 500;
    color:  darkblue;
    width: 149%;

}
.middle-text h6{
    font-size: 12px;
    margin: 4px 4px;
    color: blue;
    width: 189%;
}






@media only screen and (max-width: 640px){
    .middle-text h3{
    padding-top: 12px;
    margin: 0 4px 4px 4px;
    font-size: 15px;
    font-weight: 500;
    color:  darkblue;
    width: 160%;

}
.middle-text h6{
    font-size: 12px;
    margin: 4px 4px;
    color: blue;
    width: 189%;
    
}
.right-icons svg{
    color: darkblue;
    font-size: 25px;
    margin: 10px 152px 0px;
    text-align: right;
    width: 61%;
}
}

@media only screen and (min-width:481px) and (max-width: 580px){
    .middle-text h3{
        padding-top: 12px;
        margin: 0 4px 4px 4px;
        font-size: 15px;
        font-weight: 500;
        color:  darkblue;
        width: 160%;
    
    }
    .middle-text h6{
        font-size: 12px;
        margin: 4px 4px;
        color: blue;
        width: 189%;
        
    }
    .right-icons svg{
        color: darkblue;
        font-size: 25px;
        margin: 10px 132px 0px;
        text-align: right;
        width: 56%;
    }
}


@media only screen and (max-width: 480px){
    .middle-text h3{
    padding-top: 12px;
    margin: 0 4px 4px 4px;
    font-size: 15px;
    font-weight: 500;
    color:  darkblue;
    width: 184%;

}
.middle-text h6{
    font-size: 12px;
    margin: 4px 4px;
    color: blue;
    width: 155%;
    
}
.right-icons svg{
    color: darkblue;
    font-size: 25px;
    margin: 10px 83px 0px;
    text-align: right;
    width: 48%;
}
.faq_box {
    height: 78px;
    width: 100%;
    border-top: 1px solid darkblue;
}
}



/* @media only screen and (max-width: 820px){
    .middle-text h3{
        padding-top: 12px;
        margin: 0 4px 4px 4px;
        font-size: 15px;
        font-weight: 500;
        color:  darkblue;
        width: 184%;
    
    }
    .middle-text h6{
        font-size: 12px;
        margin: 4px 4px;
        color: blue;
        width: 185%;
        
    }
    .right-icons svg{
        color: darkblue;
        font-size: 25px;
        margin: 10px 233px 0px;
        text-align: right;
        width: 48%;
    }
    
} */

/* @media only screen and (max-width: 1024px){
    .middle-text h3{
        padding-top: 12px;
        margin: 0 4px 4px 4px;
        font-size: 15px;
        font-weight: 500;
        color:  darkblue;
        width: 250%;
    
    }
    .middle-text h6{
        font-size: 12px;
        margin: 4px 4px;
        color: blue;
        width: 316%;
        
    }
    .right-icons svg{
        color: darkblue;
        font-size: 25px;
        margin: 10px 217px 0px;
        text-align: right;
        width: 48%;
    }
} */
@media only screen and (min-width:641px) and (max-width: 1024px){
    .middle-text h3{
        padding-top: 12px;
        margin: 0 4px 4px 4px;
        font-size: 15px;
        font-weight: 500;
        color:  darkblue;
        width: 194%;
    
    }
    .middle-text h6{
        font-size: 12px;
        margin: 4px 4px;
        color: blue;
        width: 244%;
        
    }
    .right-icons svg{
        color: darkblue;
        font-size: 25px;
        margin: 10px 238px 0px;
        text-align: right;
        width: 50%;
    }
}
@media only screen and (min-width:1025px) and (max-width: 1199px){
    .right-icons svg{
        color: darkblue;
        font-size: 25px;
        margin: 10px 208px 0px;
        text-align: right;
        width: 70%;
    }
        .middle-text h3{
            padding-top: 12px;
            margin: 0 4px 4px 4px;
            font-size: 15px;
            font-weight: 500;
            color:  darkblue;
            width: 237%;
        
        }
        .middle-text h6{
            font-size: 12px;
            margin: 4px 4px;
            color: blue;
            width: 448%;
            
        }
    }

@media only screen and (min-width:1400px) and (max-width: 2500px){
    .right-icons svg{
        color: darkblue;
        font-size: 25px;
        margin: 10px 323px 0px;
        text-align: right;
        width: 70%;
    }
}