.Main-box{
    margin-top: 50px;
  }
  
  .Main-image{
    height: 40px;
    width:40px;
    border-radius: 50%;
    padding: 3px;
    background: #07198f;

}

 .Main-image img{
    height: 100%;
    width:100%;
    object-fit: cover;
    border-radius: 50%;
   border: 3px solid #dc3545;

}

.Main-card{
    background-color: rgb(28, 206, 215) !important;
    border-radius: 20px 20px 10px 10px !important;
    position: relative !important;
}


.Main-card::before{

    content: "";
    position: absolute;
    height: 25%;
    width: 100%;
    background: #07198f;
    border-radius: 20px 20px 0 0;
}

.check-icon{
position: absolute;
top: 10px;
right: 20px;
color: rgb(28, 206, 215);
}

.Main-card .Main-card-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    position: relative;
    z-index: 1;
}
.CoinDetails{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.CoinDetails .Coin-name{
    font-size: 1.2rem;
    font-weight: bold;
    font-family: 'spectral';
    padding-top: 10px;
    text-transform: uppercase;
}

.CoinDetails .Coin-symbol-red{
    font-size: 1.2rem;
    background-color: #dc3545;
    border: 1px solid transparent;
    color: white;
    border-radius: 5px;
    font-family: 'spectral';
    padding: 2px 15px;
    text-transform: uppercase;
}

.CoinDetails .Coin-symbol-green{
    font-size: 1.2rem;
    background-color: #414F12;
    border: 1px solid transparent;
    color: white;
    border-radius: 5px;
    font-family: 'spectral';
    padding: 2px 15px;
    text-transform: uppercase;
}

.CoinDetails .price{
    font-size: 1.2rem;
    font-weight: normal;
    font-family: 'open sans';
    padding-top: 5px;
}
.CoinDetails .highlited-price{
    font-size: 1.5rem;
    font-weight: 600;
    color: green;
    font-family: 'open sans';
    padding-top: 5px;
    padding-bottom: 10px;
}
.Trade-button{
   padding-top: 10px;
}
.trade-now{
    background: #07198f;
    border: 2px solid transparent;
    border-radius: 20px;
    padding: 5px 20px;
    color: #fff;
    font-weight: 500;
    transition: all 0.3s ease;
    cursor: pointer;
}
.trade-now:hover{
    background: #040f52;
   
}

.trade-now .arrow{
    color: #fff;
    /* padding-left: 5px; */
}