@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Spectral:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');


.future h1{
    font-size: 2.5rem;
    font-family: 'Spectral', serif;
    color: white;
    margin-top: 190px;
}
.future p{
font-family: 'Open Sans', sans-serif;
font-size: 1.2rem;
color: white;
}


.future_img{
    width: 400px;
    height:400px;
    margin-top: 100px;
    margin: 70px;
}

.icons_img{
    margin-top: 20px;
    width: 350px;
    height: 300px;
    margin-bottom: 60px;
}
.icon_img img{
    margin-top: 20px;
    width: 350px;
    height: 350px;
    margin-left: 120px;
    margin-bottom: 60px;
}
.future-levrage{
    background-color: #903eff;
    border-radius: 20px;
}
.leverage h2{
    font-family: 'Spectral', serif;
    padding: 40px 40px 0px 40px;
    font-size:2rem;
    color: white;
    
}
.leverage p{
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    color: white;
    padding: 0px 40px 40px 40px;
    line-height: 30px;
}
.flexibility h2{
    font-family: 'Spectral', serif;
    padding: 40px 40px 0px 40px;
    font-size: 2rem;   
    color: white;
}
.flexibility p{
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    color: white;
    line-height: 30px;
    padding: 0px 40px 40px 40px;
}


@media only screen and (max-width: 992px){
    .future h1{
        margin-top: 15px;
        font-size: 2rem;
    }
    .icons_img{
        margin-left: 120px;
    }
}


@media only screen and (max-width: 768px){
    .future h1{
        margin-top: 15px;
        font-size: 2rem;
    }
    .icons_img{
        margin-left: 120px;
    }
}



@media only screen and (max-width: 640px){
    .future h1{
        margin-top: 15px;
        font-size: 2rem;
    }
    .future p{
        font-size: 0.9rem;
    }
    .future_img{
        width: 400px;
        height:400px;
        margin: 0px;
    }
    .icons_img{
        width: 350px;
        height: 350px;
        margin: 0px;

    }
    .icon_img img{
    
        width: 350px;
        height: 350px;
        margin: 0px;
    }
    .leverage h2{
        font-size: 1.5rem;
    }
    .leverage p{
        font-size: 1rem;
    }
    .flexibility h2{
        font-size: 1.5rem;
    }
    .flexibility p{
        font-size: 1rem;
    }
}






