.career_hero h3{
    color: white;
    font-size: 1.7rem;
    font-family: 'Spectral', serif;
    margin-top: 80px;
}
.career_hero strong{
    color: brown;
}
.career_hero h1{
    color: white;
    font-size: 1.4rem;
    font-family: 'Spectral', serif;
}
.career_hero button{
    margin-top: 10px;
    border-radius: 5px;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    margin-bottom: 30px;
}
.career_hero button:hover{
    color: aqua !important;
}
.career h1{
    font-family: 'Spectral', serif;
    font-size: 1.8rem;
    color: white;
    margin-top: 80px;
}
.career h3{
    font-family: 'Spectral', serif;
    font-size: 1.4rem;
    color: white;
    margin-top: 20px;
    font-style: oblique;
    font-weight: bold;
}


.career p{
    color: white;  
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
    text-align: justify;
    font-size: 1rem;
}
.career .img-fluid{
    margin-left: 70px;
    max-width: 80%;
}
.openings h1{
    font-family: 'Spectral', serif;
    color: white;
    font-size: 1.8rem;
}
.openings h6{
    font-family: 'Spectral', serif;
    color: white;
    margin-top: 10px;
    font-size: 1.4rem;
}
.openings p{
    color: white;  
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
    font-size: 1rem;
}
.openings h3{
    font-family: 'Spectral', serif;
    color: white;
    margin-top: 20px;
    font-size: 1.8rem;
}
.openings button{
    margin-top: 10px;
    margin-bottom: 40px;
    border-radius: 5px;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
}
.openings button:hover{
    color: aqua !important; 
}




@media only screen and (max-width: 480px){
    .career .img-fluid{
        margin-left: 0px;
    }
}

@media only screen and (max-width: 640px){
    .career .img-fluid{
        margin-left: 10px;
    }
}
@media only screen and (max-width: 768px){
    .career .img-fluid{
        margin-left: 0px;
    }
}
@media only screen and (max-width: 1024px){
    .career .img-fluid{
        margin-left: 60px;
    }
}