
.features h1{
    margin-top: 100px;
    font-size: 2.5rem;
    font-family: 'Spectral', serif;
    color: white;
}
.features button{
    border-radius: 10px;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
}
.features button:hover{
    color: aqua !important;

}


.features p{
    color: white;
    font-size: 1.2rem;
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
}
.features img{
    margin-top: 30px;
}
.ex{
    background-color: #903eff;
    border-radius: 20px;
    width: 100%;
    height: auto;
}
.exchangee h1{
    color: white;
    font-size: 2rem;
    font-family: 'Spectral', serif;
    padding: 40px 40px 0px 40px;
}
.exchangee p{
    color: white;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    padding: 0px 40px 40px 40px;
    line-height: 30px;
}
.exchangee img{
    width: 430px;
    height: 350px;
} 

.margin_trading h1{
    color: white;
    font-size: 2rem;
    font-family: 'Spectral', serif;
    padding: 40px 40px 0px 40px;
}
.margin_trading p{
    color: white;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    padding: 0px 40px 40px 40px;
    line-height: 30px;
}
.margin_trading img{
    width: 380px;
    height: 350px;
} 

.Derivatives h1{
    color: white;
    font-size: 2rem;
    font-family: 'Spectral', serif;
    padding: 40px 40px 0px 40px;
}
.Derivatives p{
    color: white;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    padding: 0px 40px 40px 40px;
    line-height: 30px;
}
.Derivatives img{
    width: 380px;
    height: 350px;
}

.margin_funding h1{
    color: white;
    font-size: 2rem;
    font-family: 'Spectral', serif;
    padding: 40px 40px 0px 40px;
}
.margin_funding p{
    color: white;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    padding: 0px 40px 40px 40px;
    line-height: 30px;
}
.margin_funding img{
    width: 380px;
    height: 350px;
}
.coin{
    background-color: #C2E1A5;
    border-radius: 20px;
    width: 100%;
    height: auto;
}
#bit p{
    color: darkblue;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    padding: 0px 40px 40px 40px;
    line-height: 30px;
}
#bit h1{
    color: #306060;
    font-size: 2rem;
    font-family: 'Spectral', serif;
    padding: 40px 40px 0px 40px;
}


 @media only screen and (max-width: 1024px){
    .features h1{
        margin-top: 25px;
        font-size: 1.9rem;
    }
    
} 






 @media only screen and (max-width: 768px){
    .features h1{
        margin-top: 15px;
    }
}



@media only screen and (max-width: 640px){
    .features h1{
        margin-top: 15px;
        font-size: 1.9rem;
    }
     .features p{

        font-size: 1rem;
        margin-top: 10px;
    }
    .exchangee h1{
        font-size: 1.9rem;
        padding: 5px;
    }
    .exchangee p{
        line-height: 28px;
        padding: 0px 5px 0px 5px;
        font-size: 0.9rem;
    }
    .margin_trading h1{
        font-size: 1.9rem;   
        padding: 5px;
    }
    .margin_trading p{
        line-height: 28px;
        padding: 0px 5px 0px 5px;
        font-size: 0.9rem;
    }
    .margin_funding h1{
        font-size: 1.9rem;   
        padding: 5px;
    }
    .margin_funding p{
        line-height: 28px;
        padding: 0px 5px 0px 5px;
        font-size: 0.9rem;
    }
    .Derivatives h1{
        font-size: 1.9rem;   
        padding: 5px;
    }
    .Derivatives p{
        line-height: 28px;
        padding: 0px 5px 0px 5px;
        font-size: 0.9rem;
    }
    
}


