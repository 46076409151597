.otc_hero h1{
    font-family: 'Spectral', serif;
    color: darkblue;
    font-size: 1.8rem;
    margin-top: 80px;
}
.otc_hero p{
    color: blueviolet;  
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
    font-size: 1rem;
}
.otc_buy{
    margin:50px 0px 30px 0px;   
}
.otc_button button{
    width: 110px;
    height: 54px;
    box-shadow: 10px 10px 15px -11px rgba(0,0,0,0.53);
    font-size: 1.3rem;
    margin: 10px;
    border: 1px solid #6c757d;
    border-radius: 50px;
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Spectral', serif;
    background: rgb(203,219,227) !important;
    background: radial-gradient(circle, rgba(203,219,227,0.9262079831932774) 0%, rgba(148,187,233,0.9458158263305322) 100%);
}
.otc_button button:hover{
    border-color: black;
}
 #Buy_active{
    width: 110px;
    height: 54px;
    font-size: 1.3rem;
    margin: 10px;
    border: 1px solid #6c757d;
    border-radius: 50px;
    color: white;
    font-family: 'Spectral', serif;
    background-color: #01B0EA !important;
}
#sell_active{
    width: 110px;
    height: 54px;
    font-size: 1.3rem;
    margin: 10px;
    border: 1px solid #6c757d;
    border-radius: 50px;
    color: white;
    font-family: 'Spectral', serif;
    background-color: #01B0EA !important;
}
#twoway_active{
    width: 110px;
    height: 54px;
    font-size: 1.3rem;
    margin: 10px;
    border: 1px solid #6c757d;
    border-radius: 50px;
    color: white;
    font-family: 'Spectral', serif;
    background-color: #01B0EA !important;
}
#Buy_active:hover{
    border-color: black;
} 
#sell_active:hover{
    border-color: black;
}
#twoway_active:hover{
    border-color: black;
}
.otc_input label{
    font-size: 1rem;
    margin-left: 10px;
    margin-top: 20px;
}
.otc_input input{
    width: 280px;
    height: 38px;
    margin-left: 10px;
}
.otc_input button{
    border-radius: 0px;
    margin-left: 10px;
    width: 80px;
}
.otc_quato button{
    margin-top: 50px;
    margin-left: 149px;
    margin-right: 107px;
    width: 124px;
}





@media only screen and (max-width: 640px){
    .otc_input input{
        width: 280px;
        height: 38px;
        margin-left: 53px;
    }
    .otc_input label{
        
        margin-left: 53px;
    
    }
    .otc_input button{
        margin-left: 52px;
    }
    .otc_button button{
        width: 100px;
        margin-left: 50px;
    }
    #Buy_active{
        width: 100px;
    }
    #sell_active{
        width: 100px;
    }
    #twoway_active{
        width: 100px;
    }
}


@media only screen and (max-width: 480px){
    .otc_input label{
        
        margin-left: 5px;
    }
    .otc_input input{
        width: 240px;
        margin-left: 5px;
    }
    .otc_input button{
        margin-left: 5px;

    }
    #Buy_active{
        width: 95px;
    }
    #sell_active{
        width: 95px;
    }
    #twoway_active{
        width: 95px;
    }
    .otc_button button{
        width: 95px;
        margin-left: 10px;
    }
        .otc_quato button {

            margin-left: 115px;
            margin-right: 74px;
            
        }
}
/* @media only screen and (max-width: 768px){
    .otc_input label{
        
        margin-left: 100px;
    }
    .otc_input input{
        width: 240px;
        margin-left: 100px;
    }
    .otc_input button{
        margin-left: 5px;

    }
    #Buy_active{
        width: 120px;
        margin-left: 90px;
    }
    #sell_active{
        width: 120px;
    }
    #twoway_active{
        width: 120px;
    }
    .otc_button button{
        width: 120px;
        margin-left: 50px;
    }
        .otc_quato button {

            margin-left: 115px;
            margin-right: 74px;
            
        }
} */