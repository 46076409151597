.wallet_hero h1{
    font-size: 1.9rem;
    font-family: 'Spectral', serif;
    color: white;
    margin-top: 100px;
}
.wallet_hero p{
    color: white;  
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1rem;
     margin-top: 20px; 
}
.wallet_hero button{
    margin-top: 15px;
    height: 50px;
    width: 145px;
    font-size: 0.9rem;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
}
.wallet_hero button:hover{
    color: aqua !important;
}
.wallet_hero img{
    width: 350px;
    margin: 100px;
}
.wallet_feature h1{
    color: white;
    font-size: 2rem;
    font-family: 'Spectral', serif;
    text-align: center;
}
.wallet_feature span{
    color: white;  
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1rem;
    text-align: center;
    margin-top: 20px;
}
.wallet_feature p{
    color: white;  
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1rem;
    margin-top: 20px;
}
.wallet_feature h2{
    font-size: 1.9rem;
    font-family: 'Spectral', serif;
    color: white;
    margin-top: 100px;
}
.wallet_feature img{
    width: 430px;
    height: 300px;
    margin-top: 40px;
}
.our_wallet h1{
    color: white;
    font-size: 2rem;
    font-family: 'Spectral', serif;
    text-align: center;
}
.our_wallet span{
    color: white;  
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1rem;
    text-align: center;
    margin-top: 20px;
}
.wallet_icons{
    border-bottom: 2px solid black;
    padding-left: 5px;
    padding-bottom: 20px;
} 

.wallet_box{
    background-color: white;
    border-radius: 20px;
    margin: 30px;
    padding: 15px;
   
    
}
.wallet_icons h1{
    color: black;
    font-size: 1.2rem;
    padding-left: 25px;
    padding-top: 15px;
    font-family: 'Spectral', serif;
    font-weight: bold;
   
}
.wallet_box p{
    color: black;  
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem; 
    padding-top: 20px;
    padding-left: 5px;
}




@media only screen and (max-width: 991px){
    .wallet_hero h1{
        margin-top: 25px;
    }
    
} 


@media only screen and (max-width: 768px){
    .wallet_icons h1{
        font-size: 1rem;
        padding-left: 8px;
        padding-top: 8px;
    }
    .wallet_hero h1{
        margin-top: 15px;
    }
}











@media only screen and (max-width: 640px){
    .wallet_hero h1{
        font-size: 1.9rem;
        margin-top: 30px;
    }
    .wallet_hero img{
        
        margin: 30px 10px 10px 10px;
    }
    .wallet_feature p{
        margin-top: 10px;
    }
    .wallet_feature h2{
        font-size: 1.5rem;
        margin-top: 40px;
    }
    .wallet_feature img{
        margin-top: 0px;
    }
    .wallet_box{
        
        margin: 4px;
        padding: 6px;
        margin-top: 20px;
    }
    .wallet_icons h1{
        font-size: 0.5rem;
    }
    .wallet_box img{
        display: none;
    }
    .wallet_box p{
       
        font-size: 0.8rem; 
        
    }
    

}