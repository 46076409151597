 @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');


.contact-sort{
   
    max-width: 60vw;
    margin:30px auto;
    padding: 2rem 2rem;
    /* background-color:#97BEDC; */
    /* background: rgb(104,173,226);
    background: linear-gradient(270deg, rgba(104,173,226,0.8939950980392157) 34%, rgba(38,124,191,0.9304096638655462) 100%); */
    /* background: rgb(104,173,226);
    background: linear-gradient(270deg, rgba(104,173,226,0.8939950980392157) 50%, rgba(38,124,191,0.9304096638655462) 100%); */
    background: rgb(104,173,226);
    background: linear-gradient(270deg, rgba(104,173,226,0.8939950980392157) 50%, rgba(38,124,191,1) 100%);
    border-radius: 0.5rem;
    box-shadow: 1px 9px 4px rgba(0, 0, 0, 0.25);
    transform: translateY(50%);
}
.getstart-now h3{
    color: white;
    font-size: 1.5rem;
    padding-top: 12px;
    
}
.center-div{
    text-align: center;
}
 
.footer-color{
    
    background: rgb(2,0,36);
    background: linear-gradient(95deg, rgba(2,0,36,0.8519782913165266) 0%, rgba(9,9,121,0.8939950980392157) 57%, rgba(0,212,255,0.8827906162464986) 100%);
  
}
.footer-container{
    padding-top: 100px;
}
.footer-services{
    margin-top: 100px;
   

}
.footer-services h2{
    color: white;
    font-size:1.5rem;
    font-family: 'Spectral', serif;
}

.footer-services h5{
    color: white;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
}
.footer-services h5:hover{
   color:  #09D3FF;
}
.footer-services h5:active{
    color:  #ff096f !important;
 }
.hr{
 color:white;
}
.position{
    display: flex;
    justify-content: flex-start;
}
.social-icons{
    color: #ffff;
    display: flex;
    justify-content: flex-end;
    gap: 1.5rem;
   
}


@media only screen and (max-width: 600px) {
    .contact-sort{
        max-width: 90vw;
        margin:20px auto;
        padding: 1rem 1rem;
    }
    .getstart-now h3{
        color: white;
        font-size: 1rem;
    }
  
  }


  @media only screen and (max-width: 992px) {
    .contact-sort{
        max-width: 70vw;
        margin:30px auto;
        padding: 2rem 2rem;
    }
  }

  @media only screen and (max-width: 786px) {
    .contact-sort{
        max-width: 80vw;
        margin:30px auto;
        padding: 2rem 2rem;
    }
  }