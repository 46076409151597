.paper_hero h1{
    color: white;
    font-size: 2.8rem;
    font-weight: 300;
    padding-top: 60px;
    padding-bottom: 20px;
    font-family: 'Spectral', serif;
}
.paper_hero input{
    border-radius: 30px;
    box-sizing: border-box;
    color: #999;
    height: 50px;
    padding-left: 40px;
    padding-right: 20px;
    width: 60%;
    border: 1px solid #ffff;
    margin-bottom: 70px;
}

.question{
    width: 300px;
    height: 975px;
    border: 1px solid black;
    margin-top: 100px;
    /* background-color: darkblue; */
    background-color: rgba(0, 7, 61)
   
}
.market{
    width: 300px;
    height: 408px;
    border: 1px solid black;
    margin-top: 100px;
    /* background-color: darkblue; */
    background-color: rgba(0, 7, 61)
   
}
.question button{
    width: 298px;
    border-radius: 0px;
    background-color: transparent;
    height: 45px;
    border-color: transparent;
    color: white;
    font-size: 0.9rem;
    text-align: left;
    padding-left: 25px;
    font-family: 'Spectral';
    transition: all 0.5s;
    gap: 2rem;
    
}
.market button{
    width: 298px;
    border-radius: 0px;
    background-color: transparent;
    height: 45px;
    border-color: transparent;
    color: white;
    font-size: 0.9rem;
    text-align: left;
    padding-left: 25px;
    font-family: 'Spectral';
    transition: all 0.5s;
    gap: 2rem;
    
}
.question button:hover, .question button:focus{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
}
.market button:hover, .market button:focus{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
}
#button_active{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
}
.summary h2{
    margin-top: 100px;
    color: darkblue;
    font-family: 'Spectral';
    font-weight: bold;
}
.summary h5{
    margin-top: 30px;
    color: darkblue;
    font-family: 'Spectral';
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}
.summary h3{
    margin-top: 30px;
    color: darkblue;
    font-family: 'Spectral';
}
.summary p{
    color: blue;  
    font-family: 'Spectral';
    margin-top: 20px;
    font-size: 1rem;
    line-height: 2rem;
}
.term{
    width: 300px;
    height: 643px;
    border: 1px solid black;
    margin-top: 100px;
    /* background-color: darkblue; */
    background-color: rgba(0, 7, 61)
   
}
.term button{
    width: 298px;
    border-radius: 0px;
    background-color: transparent;
    height: 45px;
    border-color: transparent;
    color: white;
    font-size: 0.9rem;
    text-align: left;
    padding-left: 25px;
    font-family: 'Spectral';
    transition: all 0.5s;
    gap: 2rem;
    
}
.term button:hover, .term button:focus{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
}
 



