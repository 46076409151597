
.mobileApp-img{
    position: relative;
    z-index: 1;
}

.innerMobile2{
    position: absolute;
    bottom: 120px;
    right: 120px;
}

.mobile h1{
    font-size: 2.5rem;
    font-family: 'Spectral', serif;
    font-weight: bold;
}
.mobile h4{
    font-family: 'Spectral', serif;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-top: 60px;
}
.mobile p{
    color: white;
    font-size: 1.1rem;
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
}
.mobile img{
    margin-top: 30px;
}
.mobile .get-start{
    border-radius: 10px;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
}
.mobile .get-start:hover{
    color: aqua !important;

}

.inner-content-app{
    padding: 40px;
}


.ultimate_crypto h1{
    font-size: 1.8rem;
    font-family: 'Spectral', serif;
    color: white;
    font-weight: bold;
}
.ultimate_crypto h6{
    color: white;
    font-size: 0.5rem;
    font-family: 'Spectral', serif;
    /* margin-top: 20px;    */
}
.ultimate_crypto p{
    color: white;
    font-size:1rem;
    font-family: 'Open Sans', sans-serif;
    /* margin-top: 20px; */
}
.ultimate_crypto img{
    height: 400px;
    margin-left: 60px;
    width: 450px;
}
.mobile1 img{
    height: 350px;
    width: 350px;
    margin-top: 50px;
    margin-left: 100px;
    
}
.mobile1 h1{
    font-size: 2.5rem;
    font-family: 'Spectral', serif;
    color: darkblue;
    margin-top: 130px;
    font-weight: bold;
}
.mobile1 p{
    color: blue;
    font-size: 1.2rem;
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
}
.mobile1 img{
    height: 350px;
    width: 350px;
    margin-top: 50px;
    margin-left: 100px;
    
}
.mobile2 h1{
    font-size: 2.5rem;
    font-family: 'Spectral', serif;
    color: darkblue;
    margin-top: 130px;
    font-weight: bold;
}
.mobile2 p{
    color: blue;
    font-size: 1.2rem;
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
}
.mobile2 img{
    height: 350px;
    width: 350px;
    margin-top: 50px;
    margin-right: 100px;
    
}
.mobile3 h1{
    font-size: 2.5rem;
    font-family: 'Spectral', serif;
    color: darkblue;
    margin-top: 130px;
    font-weight: bold;
}
.mobile3 p{
    color: blue;
    font-size: 1.2rem;
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
}
.mobile3 img{
    height: 350px;
    width: 350px;
    margin-top: 50px;
    margin-left: 100px;
    
}


.btcImg-div{
    width: 50px;
    height: 50px;
}

.outer-finger{
    position: relative;
    z-index: 1;
}
.innerImg2{
    position: absolute;
    left: 40px;
    background-repeat: no-repeat;
    background:cover;
    width: 209px;
    height: 400px;
   top: 0px;
}
.innerImg3{
    position: absolute;
    left: 40px;
    padding: 80px;
     width: 30px;
     height: 30px;
   top: 0px;
}

.innerImg4{
    position: absolute;
    left:0px;
    background-repeat: no-repeat;
    background:cover;
    width: 200px;
    height:200px;
   top: -30px;
}


@media only screen and (max-width: 480px){
    .ultimate_crypto img{
        margin-left: 0px !important;
        margin-top: 25px;
    }
}
@media only screen and (max-width: 768px){
    .ultimate_crypto img{
        margin-top: 25px;
    }
}
@media only screen and (max-width: 1024px){
    .ultimate_crypto img{
        margin-left: 45px;
    }
}


@media only screen and (max-width: 640px){
    .ultimate_crypto img{
        margin-left: 0px;
        margin-top: 25px;
    }
}