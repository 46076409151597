@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Spectral:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500&family=Josefin+Sans:wght@300;400;500&family=Poppins:wght@700&family=Roboto:wght@300&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --navheader: #05055a;
  --bgsec-1: #0a0acf;
  --bgsec-2: #030361;
  --heding-color: linear-gradient(rgba(255, 210, 0, 1), rgba(247, 151, 30, 1));
  --yellow-red-gradient: linear-gradient(rgba(255, 210, 0, 1), rgba(247, 30, 30, 1));
  --Cyan-perple-gradient: linear-gradient(rgba(0, 224, 255, 1), rgba(60, 30, 247, 1));
  --para-color: #ffff;
  --inpute-feildbg-color: #24A3BF;
  --cyan-color: #09D3FF;
  --heading-text-font: 'Spectral', serif;
  --para-text-font: 'Open Sans', sans-serif;
  --button-cyan-gradient:linear-gradient(90deg, #6DDFF6 0%, #6366FF 100%);
  --button-pink-gradient:linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);

}


body {
  /* background-image: linear-gradient(to right top, #04c1f1, #0095d7, #0069b7, #003e91, #0e1065); */
 
    background: rgb(203,219,227) ;
background: radial-gradient(circle, rgba(203,219,227,0.8393732492997199) 0%, rgba(148,187,233,0.8113620448179272) 100%); 
}

/* Header Nav styling Here */

.navbg {
  background: rgb(0,212,255);
background: linear-gradient(95deg, rgba(0,212,255,0.8827906162464986) 0%, rgba(9,9,121,0.9416141456582633) 65%, rgba(2,0,36,0.9752275910364145) 100%);
  filter: drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.25));
}

.logo {
  color: aliceblue !important;
  font-family: 'Spectral';
  font-weight: 400;
}

.main-nav,
a#basic-nav-dropdown,
a.nav-link {
  color: aliceblue !important;
}

a.nav-link:hover,
a#basic-nav-dropdown:hover{
  color: #09D3FF !important;
  font-weight: bold;
}
a.nav-link:active,
a#basic-nav-dropdown:active{
  color: #ff096f !important;
  font-weight: bold;
}

.nav-btn{
 background: var(--button-pink-gradient);
  margin: 0 1rem 0 0;
}
.login {
  padding: 0 1rem;
}


/* navbar end */

.sign-up-btn{
  background: var(--button-pink-gradient);
  transition: all linear 0.3;
}
.sign-in-btn{
  background: var(--button-cyan-gradient);
  transition: all linear 0.3;
}
.sign-in-btn:hover,
.sign-up-btn:hover{
  color: black;
}
.hide .dropdown-toggle{
 visibility: hiden;
}
.hide .dropdown-toggle::after{
  display: none;
}

.hide svg{
  height: 1.5em;
  width: 1.5em;
}
.hide .dropdown-menu[data-bs-popper]{
  top: 100%;
    right: 0px;
    left: auto;
    margin-top: var(--bs-dropdown-spacer);
}

.toggle-side .dropdown-menu[data-bs-popper]{
  top: 100%;
    right: 0px;
    left: auto;
    margin-top: var(--bs-dropdown-spacer);
}





