.Market-section {
    background-color: #030361;
    width: 100%;
    height: 100vh;
}
.main-container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:40px;
}
.new-listing-heading {
    color: #fff;
    font-family: 'spectral';
    font-weight: 600;
    padding-top: 60px;
    padding-bottom: 20px;
    text-align: center;
    position: relative;
}
.new-listing-heading::before {
   content: "";
   bottom: -10;
   left: 46%;
   width: 100px;
   /* height: 1px; */
   border: 1px dotted red;
   border-radius: 10px;
   /* background-color: white; */
 

   position: absolute;

}





.Market-fav-section {
    background-color: #0e0ed1;
    width: 100%;
    height: 130vh;
}

/* .Market-fav-section h2{
    margin-top: 80px;
} */

.Market-news-section {
    background-color: #030361;
    width: 100%;
    height: 90vh;
}

.market-nav {
    color: white;
    text-decoration: none !important;
    background: #030361;
    padding: 10px 20px;
    border: 2px solid transparent;
    border-radius: 10px;
    font-family: 'spectral';
    font-size: 1rem;
    margin-right: 8px;
}
.market-nav:hover{
    background-color: #02021d;
}


@media only screen and (max-width: 1024px){
    .new-listing-heading::before {
        bottom: 57;
        left: 45%;
     
     }
     .Main-container {
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
      }
     .Market-section {
           
        height: 100vh;
    }
    .Market-fav-section {
       
        height: 100vh;
    }
    .Market-news-section {
       
        height: 80vh;
    }

}


@media only screen and (max-width: 768px){
.Main-card .Main-card-content {
    display: flex;
    align-items: flex-start;
    padding: 30px;
    position: relative;
    z-index: 1;
}
.Main-container {
    grid-template-columns: repeat(2, 1fr);
  }

.new-listing-heading::before {
    bottom: 57;
    left: 43%;
 
 }
 .Market-section {
       
    height: 100vh;
}
.Market-fav-section {
   
    height: 125vh;
}
.Market-news-section {
   
    height: 50vh;
}
}



@media only screen and (max-width: 640px){
    .new-listing-heading::before {
        bottom: 57;
        left: 41%;
     
     }
     .Main-container {
        grid-template-columns: repeat(2, 1fr);
      
      }
     .Market-section {
       
        height: 115vh;
    }
    .Market-fav-section {
       
        height: 280vh;
    }
    .Market-news-section {
       
        height: 175vh;
    }
     
}



@media only screen and (max-width: 480px){
    .new-listing-heading::before {
        bottom: 57;
        left: 37%;
     
     }
     .Main-container {
        grid-template-columns: repeat(1, 1fr);
      
      }
     
     .Market-section {
       
        height: 80vh;
    }
     

    .Market-fav-section {
       
        height: 180vh;
        width: 100%;
    }
    .Market-news-section {
       
        height: 110vh;
    }
    
}



@media only screen and (max-width: 360px){
    .new-listing-heading::before {
        bottom: 57;
        left: 37%;
     
     }
     .Main-container {
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
      }
     .Market-section {
       
        height: 80vh;
    }
     

    .Market-fav-section {
       
        height: 370vh;
        width: 100%;
    }
    .Market-news-section {
       
        height: 110vh;
    }
    
}