.Container12{
    max-width: 1140px; 
     margin:auto; 
}
.navbar-link {
    text-decoration: none;
  }
.CoinHead{
    color: orange;
    font-weight: 600;
}
.heading123{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background-color: #1056be;
    box-shadow: 0px 0px 12px #18191b;
    border-radius: 8px;
    margin: 2rem 1rem;
    padding: .7rem 1rem;
    font-weight: 700;
}

.coin_name {
    margin-left: -4rem;
}

.coin_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffff;
    box-shadow: 0px 0px 12px #18191b;
    border-radius: 8px;
    margin: 2rem 1rem;
    padding: .7rem 1rem;
}

.coin_row:hover {
    transform: scale(1.04);
    transition: .3s ease-in-out;
    cursor: pointer;
}

.coinImages {
    height: auto;
    margin-right: 8px;
    width: 30px;
}

.img_symbol {
    display: flex;
    align-items: center;
}
@media screen and (max-width: 720px) {
    .hide-mobileView {
        display: none;
    }
}