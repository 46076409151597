
.ekonnet_hero h1{
    font-family: 'Spectral', serif;
    color: white;
    font-size: 1.8rem;
    margin-top: 80px;
}
.ekonnet_hero h3{
    font-family: 'Spectral', serif;
    font-size: 1.4rem;
    color: white;
    margin-top: 10px;
}
.ekonnet_hero p{
    color: white;  
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
    font-size: 1rem;
}
.ekonnet_hero button{
    margin-top: 10px;
    border-radius: 5px;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
}
.ekonnet_hero button:hover{
    color: aqua !important;
}
.what_ekonnet h1{
    font-family: 'Spectral', serif;
    color: white;
    font-size: 1.8rem;
}
.what_ekonnet p{
    color: white;  
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
    font-size: 1rem;
}
.why_ekonnet h1{
    font-family: 'Spectral', serif;
    color: white;
    font-size: 1.8rem;
    margin-bottom: 40px;
}
.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
  }
  .flip-card-inner {
    position: relative;
    width: 80%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    color: white;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    border-radius: 10px;
    box-shadow: 0px 5px 20px black;
    font-family: 'Spectral', serif;
    font-size: 2.4rem;
    padding: 145px 46px 145px 46px

}

  .flip-card-back {
    background-color: #2980b9;
    color: white;
    transform: rotateY(180deg);
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    padding: 70px 40px 70px 40px;
  }
.flexible h1{
    font-family: 'Spectral', serif;
    color: white;
    font-size: 1.8rem;
    margin-bottom: 0px;
}
.flexible h3{
    font-family: 'Spectral', serif;
    font-size: 1.4rem;
    color: white;
    margin-top: 20px;
}
.flexible p{
    color: oldlace;  
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
    font-size: 1rem;
    text-align: center;
}
.flax{
    background-color: #102331;
    margin-left: 70px;
}
.option h1{
    font-family: 'Spectral', serif;
    color: white;
    font-size: 1.8rem;
    margin-top: 30px;
    position: relative;

}
.option p{
    color: white;  
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
    font-size: 1rem;
    text-align: start;
}
.ekonnet_crypto h1{
    font-family: 'Spectral', serif;
    color: white;
    font-size: 1.8rem;
    margin-top: 20px;
}
.ekonnet_crypto p{
    color: white;  
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
    font-size: 1rem;
    text-align: start;
}


@media only screen and (max-width: 768px){
    .flax{
        margin-left: 0px;
        margin-top: 0px;
    }
    .flip-card{
        margin-left: 208px;
    }
}


@media only screen and (max-width: 991px){
    .flip-card{
        width:255px;
    }
    .flax{
        margin-left: 20px;
        margin-top: 0px;
    }
}


@media only screen and (max-width: 1024px){
    .flip-card{
        width:255px;
    }
    .flax{
        margin-left: 20px;
        margin-top: 0px;
    }
}


@media only screen and (max-width: 640px){
    .flax{
        margin-left: 0px;
        margin-top: 0px;
    }
    .flip-card{
        margin-left: 120px;
    }
}

@media only screen and (max-width: 480px){
    
    .flip-card{
        margin-left: 50px;
    }
}