.one_click{
    margin:120px 0px 30px 0px;   
}
.one_click_button button{
    width: 130px;
    height: 40px;
    box-shadow: 10px 10px 15px -11px rgba(0,0,0,0.53);
    font-size: 0.9rem;
    margin: 16px 25px 16px 0px;
    font-weight: bold;
    border: 1px solid #6c757d;
    border-radius: 50px;
    
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Spectral', serif;
    background: rgb(203,219,227) !important;
    background: radial-gradient(circle, rgba(203,219,227,0.9262079831932774) 0%, rgba(148,187,233,0.9458158263305322) 100%);
}
 #one_click_active{
    width: 130px;
    height: 40px;
    font-weight: bold;
    font-size: 0.9rem;
    margin: 16px 25px 16px 0px;
    border: 1px solid #6c757d;
    border-radius: 50px;
    color: white;
    font-family: 'Spectral', serif;
    background-color: #01B0EA !important;
}
#one_click_active:hover{
    border-color: black;
}
.one_click_button button:hover{
    border-color: black;
}
.one_click_buy{
    /* width: 600px; */
    height: 500px;
    border: 1px solid transparent;
    /* margin: 40px 430px 40px 430px;  */
}
.one_click_buy h1{
    color:rgba(0, 0, 0, 0.87);
    font-size: 2rem;
    font-family: 'Spectral', serif;
    margin-top: 15px;
}
#one_click_buy_border{
border-bottom: 3px solid #01B0EA;

}
#one_click_sell_border{
    border-bottom: 3px solid #01B0EA;
    }
.one_click_buy label{
    padding-left: 17px;
    font-size: 1.4rem;
    font-family: 'Spectral', serif;
    margin-top: 15px;
}
.one_click_buy input{
    margin-left: 17px;
    font-size: 1.2rem;
    background-color: transparent;
    border: 1px solid #6c757d;
    width: 290px;
    height: 50px;
    padding-left:10px;
    color: rgba(0, 0, 0, 0.87);

}
.one_click_buy input:hover{
    border-color: black;
}
.one_click_buy button{
    margin-left: 7px;
    font-size: 1rem;
    background-color: transparent;
    border: 1px solid #6c757d;
    width: 98px;
    height: 50px;
    margin-top: 48px;
    color: rgba(0, 0, 0, 0.87);
}
.one_click_buy button:hover{
    border-color: black
}
.one_click_buy ul{
    width: 250px;
    height: 50px;
    border: 1px solid #6c757d;
    list-style: none;
    font-size: 1.1rem;
    padding-left: 10px;
    margin-left: 40px;
    color: rgba(0, 0, 0, 0.87);
}
.one_click_buy ul:hover{
    border-color: black;
   
}
.one_click_buy ul li{
    margin: 8px;
    
}

.one_click_buy_login button{
    margin: 60px 60px 60px 137px;
    width: 150px;
    background-color: #01B0EA;
    color: #ffffff;
    font-size: 1.2rem;
    box-shadow: 10px 10px 14px -7px rgba(0,0,0,0.75);

}
.one_click_sell_login button{
    margin: 60px 60px 60px 137px;
    width: 150px;
    background-color: #01B0EA;
    color: #ffffff;
    font-size: 1.2rem;
    box-shadow: 10px 10px 14px -7px rgba(0,0,0,0.75);

}
.paymet input{
    width: 398px;
}




@media only screen and (max-width: 768px){
    .one_click {
        margin: 50px 145px 30px 157px;
    }
    .one_click_buy h1 {
    
        margin-right: 110px;
        margin-left: 180px;
    }
    .one_click_buy input {
        width: 350px;
        margin-left: 126px;
    }
    .one_click_buy label{
        padding-left: 126px;
        
    }
    .one_click_buy button {
        margin-right: 126px;
    }
    .payment input {
        width: 456px;
    }
    .one_click_buy_login button {
        margin: 50px -58px 0px 280px;
    
    }
    .one_click_sell_login button{
        margin: 50px -58px 0px 280px;
     
    }

}








@media only screen and (max-width: 640px){
    
.one_click {
    margin: 50px 145px 30px 80px;
}
.one_click_buy h1 {
    
    margin-right: 153px;
    margin-left: 68px;
}
.one_click_buy input {
    width: 258px;
    margin-left: 83px;
}
.one_click_buy label{
    padding-left: 83px;
    
}
.one_click_buy button{
    margin-right: 60px;
}
.payment input {
    width: 373px;
}
.one_click_buy_login button {
    margin: 50px -58px 0px 190px;

}
.one_click_sell_login button{
    margin: 50px -58px 0px 190px;
 
 
 }
}




 @media only screen and (max-width: 480px)
 {
    
.one_click {
    margin: 50px 0px 30px 24px;
}
#one_click_active {
   
    margin: 16px 9px 16px 0px;
    font-size: 0.7rem;
    width: 100px;
}
.one_click_button button {
    margin: 16px 9px 16px 0px;
    font-size: 0.7rem;
    width: 100px;
    
}
.one_click_buy h1 {
    
    margin-right: 51px;
    margin-left: 60px;
}
.one_click_buy input {
    width: 210px;
    margin-left: 30px;
}

.one_click_buy label{
    padding-left: 30px;
    
}
.payment input {
    width: 314px;
    
}
.one_click_buy_login button {
        margin: 60px 0px 0px 130px;

}
.one_click_sell_login button {
    margin: 60px 0px 0px 130px;

}

.one_click_buy button {
    margin-right: 0px;
}

}














 
