.welcome__main{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding-top: 75px;
    
    }
    .blur-main{
        width: 100%;
        height: 40vh;
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(2px);
    }

    .wellcome__ekonnet{
        /* margin-top: 30px; */
    color: aliceblue;
    font-size:2rem;
    font-weight: 600;
    font-family: 'Spectral';
    }
    .search__form{
        width: 50%;
        height: 50px;
        background-color: beige;
        border: 2px solid transparent;
        border-radius: 30px;
        text-align: center;
        align-items: center;
        
    }
    .search__icon-div{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        gap: 1;
        color: rgb(79, 75, 75);
    }
    
    .search__icon-div span{
        padding-left: 15px;
    }

.box__heplcenter{

    width: 32% !important;
    height: "auto";
    background:radial-gradient(circle at 15% 40%, rgba(62, 53, 121, 1) 0%, rgba(5, 74, 84, 0.91) 100%);

    border: 2px solid transparent;
    border-radius: 20px;
    box-shadow: 3px 3px 9px 0px rgba(18,161,168,1);
    text-align: start;
}
.box__heplcenter:hover{
    background:linear-gradient(90deg, rgba(26, 18, 81, 1) 0%, rgba(1, 21, 24, 0.91) 100%);
 
    color:#059cb2f0;
    cursor: pointer;
    transition: all 0.3s ease-in;

}

.circle__icon{
    width: 70px;
    height: 70px;
    border: 2px solid transparent;
    border-radius: 50%;
    background:radial-gradient(circle at 52% 46%, rgba(0, 224, 255, 1) 0%, rgba(5, 156, 178, 0.94) 100%);
    margin: 30px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    padding: 20px;
}
.circle__icon:hover{
border: 2px solid rgb(228, 192, 9);
}

.circle__icon .icon{
    color: darkblue;
    font-size: 2rem;
    border: 1px dashed purple;
    
    border-radius: 50%;

}

.div__arrow-icon{
color:rgba(5, 156, 178, 0.94);
}






.service__heplcenter{
    color: white;
    text-align: center;
    font-family: 'Spectral';
    font-weight: 600;
}



.acount-main-heading{
    color: darkblue;
    text-align: center;
    margin: 40px 20px 30px 50px;
    font-weight: 600;
    font-family: 'Spectral';
    position: relative;
}
.acount-main-heading::after{
    content:"";
    position: absolute;
    left: 529px;
    top: 40px;
   
    width: 86px;
    border: 4px solid rgba(5, 74, 84, 0.91) ;
    border-color: radial-gradient(circle at 15% 40%, rgba(62, 53, 121, 1) 0%, rgba(5, 74, 84, 0.91) 100%);
  
    transform: translateX(50% 50%);
    border-radius: 10px;

   
}


.accountDiv{
    background:radial-gradient(circle at 15% 40%, rgba(62, 53, 121, 1) 0%, rgba(5, 74, 84, 0.91) 100%);
  
    border-radius: 10px;
}
.acount__heading{
    color: white;
    font-size: 1.2rem;
    padding: 15px;
}
.accountDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 33% !important;
}
.accountDiv .arrow-icon{
    color: #ffff;
}


.footer__contaioner{
    background:radial-gradient(circle at 15% 40%, rgba(62, 53, 121, 1) 0%, rgba(5, 74, 84, 0.91) 100%);
    width: 100%;
    height: 60vh;
    margin-top: 90px;
}

.footer__data{
    padding-top: 30px;
}

.footer__data h2{
    color: white;
    text-transform: capitalize;
}
.footer__data p{
    color: rgb(209, 202, 202);
}
.footer__data button{
    padding: 8px;
  border: 2px solid transparent;
  border-radius: 2px;
  background-color:rgba(5, 156, 178, 0.94);
  color: white;
}


/* ====================================Getting started page start============== */
.content__blur{
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
}

.getingStartedDiv{
    width: "auto";
    height: 40vh;
/* background: darkblue; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 20px;
}

.gettingStartHeading{
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'Spectral';
    color: rgb(5, 5, 41);
    background-color:rgba(5, 156, 178, 0.94);
    padding: 10px 20px;
    border-radius: 10px;
}

.sublink-getstart{
    line-height: 2rem;
    font-size: 1rem;
}

.breadcumb-color{
    color: white;
}

/* ====================================Getting started page ============== */

/* .sidebar__section{
    background-color: ;
} */






.sidebar__container .main-data{
    width: 100%;
    padding-left: 40px;
    color: darkblue;
    padding-top: 50px;
    font-family: 'Spectral';
    font-weight: 600;
    font-size: 1rem;
    
}

.top__section{
width: 100%;
height: auto;
background:linear-gradient(258deg, rgba(171, 130, 238, 0.82) 0%, rgba(0, 130, 255, 0.82) 99%);


}
.sidebar-div{
    width: 200px;
    color: white;
    height: auto;
    background-color: rgba(0, 7, 61);
}

.link{
    transition: all 0.5s;
    gap: 2rem;
    color: white;
    padding: 5px 20px;
    transition: 0.2s cubic-bezier(0.6,-0.28,0.735,0.045);
}
.link:hover{
    border-right: 4px solid white;
    transition: 0.2s cubic-bezier(0.6,-0.28,0.735,0.045);
    background: rgb(45,51,89); 
    color: white;
}
.active{
    background: rgb(45,51,89); 
    color: white;
}


#common-search-hero{
    background:linear-gradient(258deg, rgba(171, 130, 238, 0.82) 0%, rgba(0, 130, 255, 0.82) 99%);
    height: 40vh;
    margin-top: 0;
    background-size: cover;

}







@media only screen and (max-width: 640px){
    .acount-main-heading::after{
        left: 228px;
    }
    .wellcome__ekonnet{
    
    font-size:1.9rem;
    
    }
    .search__form{
       
        height: 35px;
        
    }
    .service__heplcenter {
        
        font-size: 21px;
    }
    .footer__contaioner{
        
       
        height: 90vh;
       
    }
    .acount__heading{
        font-size: 1rem;
    }
    
}
@media only screen and (max-width: 768px){
    .acount-main-heading::after{
        left: 317px;
    }
    .footer__contaioner{
        
       
        height: 25vh;
       
    }
}

@media only screen and (max-width: 1024px){
    .acount-main-heading::after{
        left: 438px;
    }
    .footer__contaioner{
        
       
        height: 25vh;
       
    }
}



@media only screen and (max-width: 480px){
    .acount-main-heading::after{
        left: 157px;
    }
    .wellcome__ekonnet{
    
    font-size:1.2rem;
    
    }
    .search__form{
       
        height: 65px;
        width: 80%;
    }
    .service__heplcenter {
        
        font-size: 15px;
    }
    .footer__contaioner{
        
       
        height: 50vh;
       
    }
    .acount__heading {
        font-size: 0.7rem;
    }
    .box__heplcenter{

       
       
        box-shadow: none;
       
    }
    .acount-main-heading{
       
        margin: 40px 0px 30px 0px;
       
    }
}
    /* @media only screen and (max-width: 640px){
    .acount-main-heading::after{
        left: 228px;
    }
    .wellcome__ekonnet{
    
    font-size:1.9rem;
    
    }
    .search__form{
       
        height: 45px;
        
    }
    .service__heplcenter {
        
        font-size: 21px;
    }
    .footer__contaioner{
        
       
        height: 90vh;
       
    }
} */
@media only screen and (max-width: 360px){
    .acount-main-heading::after{
        left: 137px;
    }
    .footer__contaioner{
        
       
        height: 65vh;
       
    }
}