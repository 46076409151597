.terminal_hero h1{
    margin-top: 100px;
    font-size: 2.5rem;
    font-family: 'Spectral', serif;
    color: white;
}
.terminal_hero button{
    border-radius: 10px;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
}
.terminal_hero button:hover{
    color: aqua !important;

}
.terminal_hero p{
    color: white;
    font-size: 1.2rem;
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
}
.terminal_box{
    background-color: steelblue;
    border: 1px solid transparent;
    color: white;
}
.terminal_box h1{
    font-size: 2rem;
    color: darkblue;
}
.terminal_box h4{
    font-size: 1.5rem;
}
.terminal_box p{
    font-size: 1.2rem;
    opacity: 0.5;
}
.terminal1 h1{
    margin-top: 100px;
    font-size: 2.8rem;
    font-family: 'Spectral', serif;
    color: white;
}
.terminal1 p{
    color: white;
    font-size: 1.2rem;
    font-family: 'Open Sans', sans-serif;
    margin-top: 100px;
    opacity: 0.5;
}