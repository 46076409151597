.access h3{
    color: darkblue;
    margin-top: 60px;
    font-family: 'Spectral', serif;
    font-size: 2.5rem;
}
.access h4{
    color: darkblue;
    margin-top: 40px;
    font-family: 'Spectral', serif;
    margin-bottom: 20px;
}
.access ul{
    color: darkblue;
    list-style-type: disc;
    margin-bottom: 150px;
}
.access ul li{
    color: blue;
    font-family: 'Spectral', serif;
    font-size: 1.1rem;
    margin-top: 16px;
    margin-left: -13px;
}



.paper_hero h1{
    color: white;
    font-size: 2.8rem;
    font-weight: 300;
    padding-top: 60px;
    padding-bottom: 20px;
    font-family: 'Spectral', serif;
}
.paper_hero input{
    border-radius: 30px;
    box-sizing: border-box;
    color: #999;
    height: 50px;
    padding-left: 40px;
    padding-right: 20px;
    width: 60%;
    border: 1px solid #ffff;
    margin-bottom: 70px;
}

/* .question{
    width: 300px;
    height: 975px;
    border: 1px solid black;
    margin-top: 100px;
     background-color: darkblue; 
    background-color: rgba(0, 7, 61)
   
} */
/* .market{
    width: 300px;
    height: 408px;
    border: 1px solid black;
    margin-top: 100px;
    background-color: darkblue; 
    background-color: rgba(0, 7, 61)
   
} */
/* .question button{
    width: 298px;
    border-radius: 0px;
    background-color: transparent;
    height: 45px;
    border-color: transparent;
    color: white;
    font-size: 0.9rem;
    text-align: left;
    padding-left: 25px;
    font-family: 'Spectral';
    transition: all 0.5s;
    gap: 2rem;
    
} */
/* .market button{
    width: 298px;
    border-radius: 0px;
    background-color: transparent;
    height: 45px;
    border-color: transparent;
    color: white;
    font-size: 0.9rem;
    text-align: left;
    padding-left: 25px;
    font-family: 'Spectral';
    transition: all 0.5s;
    gap: 2rem;
    
} */
/* .question button:hover, .question button:focus{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
}
.market button:hover, .market button:focus{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
} */
#button_active{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
}
.summer h2{
    margin-top: 100px;
    color: darkblue;
    font-family: 'Spectral';
    
}
.summer h5{
    margin-top: 30px;
    color: darkblue;
    font-family: 'Spectral';
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}
.summer h4{
    margin-top: 30px;
    color: darkblue;
    font-family: 'Spectral';
}
.summer h3{
    color: darkblue;
    font-family: 'Spectral';
}
.summer p{
    color: blue;  
    font-family: 'Spectral';
    margin-top: 20px;
    font-size: 1rem;
    line-height: 2rem;
}
.summer li{
    list-style: disc;
}
.lost{
    width: 300px;
    height: 145px;
    border: 1px solid black;
    margin-top: 100px;
    /* background-color: darkblue; */
    background-color: rgba(0, 7, 61)
   
}
.lost button{
    width: 298px;
    border-radius: 0px;
    margin-top: 1px;
    background-color: transparent;
    height: 45px;
    border-color: transparent;
    color: white;
    font-size: 0.9rem;
    text-align: left;
    padding-left: 25px;
    font-family: 'Spectral';
    transition: all 0.5s;
    gap: 2rem;
    
}
.verify button:hover, .verify button:focus{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
}
 
.verify{
    width: 300px;
    height: 285px;
    border: 1px solid black;
    margin-top: 100px;
    /* background-color: darkblue; */
    background-color: rgba(0, 7, 61)
   
}
.verify button{
    width: 298px;
    border-radius: 0px;
    background-color: transparent;
    height: 45px;
    margin-top: 1px;
    border-color: transparent;
    color: white;
    font-size: 0.9rem;
    text-align: left;
    padding-left: 25px;
    font-family: 'Spectral';
    transition: all 0.5s;
    gap: 2rem;
    
}
.lost button:hover, .lost button:focus{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
}
.tb{
    font-family: 'Spectral', serif;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 2px;
}
.tb td{
    font-family: 'Spectral', serif;
    font-weight: 600;
    text-align: center;
    width: 25%;
    
    border-collapse: collapse;
}
.tab{
    font-family: 'Spectral', serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid;
}
.tab th{
    text-align: center;
    padding: 10px;
}
.tab td{
    border: 1px solid;
    text-align: center;
    width: 25%;
    border-collapse: collapse;
    padding-top: 10px;
    padding-bottom: 10px;
}
.report button:hover, .report button:focus{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
}
 
.report{
    width: 300px;
    height: 365x;
    border: 1px solid black;
    margin-top: 100px;
    background-color: rgba(0, 7, 61)
   
}
.report button{
    width: 298px;
    margin-top: 1px;
    border-radius: 0px;
    background-color: transparent;
    height: 45px;
    border-color: transparent;
    color: white;
    font-size: 0.9rem;
    text-align: left;
    padding-left: 25px;
    font-family: 'Spectral';
    transition: all 0.5s;
    gap: 2rem;
    
}

.trade button:hover, .trade button:focus{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
}
 
.trade{
    width: 300px;
    height: 1371px;
    border: 1px solid black;
    margin-top: 100px;
    /* background-color: darkblue; */
    background-color: rgba(0, 7, 61);
    margin-bottom: 10px;
   
}
.marg button{
    width: 298px;
    border-radius: 0px;
    background-color: transparent;
    height: 64px;
    margin-top: 1px;
    border-color: transparent;
    color: white;
    font-size: 0.9rem;
    text-align: left;
    padding-left: 25px;
    font-family: 'Spectral';
    transition: all 0.5s;
    gap: 2rem;
    
}
.marg button:hover, .marg button:focus{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
}
 
.marg{
    width: 300px;
    height: 789px;
    border: 1px solid black;
    margin-top: 100px;
    /* background-color: darkblue; */
    background-color: rgba(0, 7, 61);
    margin-bottom: 10px;
   
}
.trade button{
    width: 298px;
    border-radius: 0px;
    background-color: transparent;
    height: 64px;
    margin-top: 1px;
    border-color: transparent;
    color: white;
    font-size: 0.9rem;
    text-align: left;
    padding-left: 25px;
    font-family: 'Spectral';
    transition: all 0.5s;
    gap: 2rem;
    
}
.derivat button:hover, .derivat button:focus{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
}
 
.derivat{
    width: 300px;
    height: 263px;
    border: 1px solid black;
    margin-top: 100px;
    /* background-color: darkblue; */
    background-color: rgba(0, 7, 61);
    margin-bottom: 10px;
   
}
.derivat button{
    width: 298px;
    border-radius: 0px;
    background-color: transparent;
    height: 64px;
    margin-top: 1px;
    border-color: transparent;
    color: white;
    font-size: 0.9rem;
    text-align: left;
    padding-left: 25px;
    font-family: 'Spectral';
    transition: all 0.5s;
    gap: 2rem;
    
}
.honey button:hover, .honey button:focus{
    border-color: #0d6efd;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
    color: white;
}
 
.honey{
    width: 300px;
    height: 133px;
    border: 1px solid black;
    margin-top: 100px;
    /* background-color: darkblue; */
    background-color: rgba(0, 7, 61);
    margin-bottom: 10px;
   
}
.honey button{
    width: 298px;
    border-radius: 0px;
    background-color: transparent;
    height: 64px;
    margin-top: 1px;
    border-color: transparent;
    color: white;
    font-size: 0.9rem;
    text-align: left;
    padding-left: 25px;
    font-family: 'Spectral';
    transition: all 0.5s;
    gap: 2rem;
    
}



