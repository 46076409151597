.news h1{
    font-family: 'Spectral', serif;
    color: darkblue;
    font-size: 2.5rem;
    margin-top: 80px;
    font-weight: bold;
}
.news span{
    color: blue;  
    font-family: 'josefin Sans';
    margin-top: 3px;
    font-size: 1.5rem;
}
.news_section{
    width: 355px;
    height: 416px;
    border: 1px solid transparent;
    margin-top: 60px;
    position: relative;
    box-shadow: 10px 10px 5px 0px rgba(177,196,199,1);

}
.news_section img{
    width: auto;
    transition: all 600ms cubic-bezier(0.58, 0.4, 0.58, 1) 0s;
}
.news_section img:hover{
    opacity: .8;
    transform: scale(1.05);
    
}
.time-n-date{
    position: absolute;
    z-index: 1;
    padding: 5px 12px;
    font-weight: bold;
    box-shadow: 0 0 25px rgba(0,0,0,.3);
    background: #ffd600;
    font-size: 13px;
    border-radius: 5px;
    top: 20px;
    left: 15px;
}
.news .news_section h1{
    font-family: 'Spectral', serif;
    color: darkblue;
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 30px;
    padding-left: 20px;
}
.news_section p{
    color: #5d5779;
    font-family: 'Open Sans', sans-serif;
    margin-top: 5px;
    font-size: 0.9rem;
    border-bottom: 1px solid lightsteelblue;
    padding-left: 20px;
    padding-bottom: 15px;
}
.news_section .bottom_news_section p{
    color: #5d5779;
    font-size: 0.8rem;
    border-bottom: none;
    margin-top: 0px;
    padding-left: 20px;
}
.mar_section marquee{
  background-color: darkblue;
  color: white;
  margin-top: 20px;
  font-size: 1.1rem;
  width: 100%;
  white-space: nowrap;
  float: left; 
  overflow: hidden;
}


@media only screen and (max-width: 640px){

    .news_section {
        width: 529px;
        height: 508px;
    }
}
@media only screen and (max-width: 768px){

    .news_section {
        width: 650px;
        height: 585px;
    }
}
@media only screen and (max-width: 480px){

    .news_section{
        width: 355px;
        height: 416px;
    }
}

@media only screen and (max-width: 1024px){
.news_section {
    width: 302px;
    height: 390px;
}
}
