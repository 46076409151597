.fiat_deposite{
    margin:150px 0px 30px 0px;   
}
.fiat_deposite_button button{
    width: 120px;
    height: 40px;
    box-shadow: 10px 10px 15px -11px rgba(0,0,0,0.53);
    font-size: 0.9rem;
    margin: 16px 25px 16px 0px;
    font-weight: bold;
    border: 1px solid #6c757d;
    border-radius: 50px;
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Spectral', serif;
    background: rgb(203,219,227) !important;
    background: radial-gradient(circle, rgba(203,219,227,0.9262079831932774) 0%, rgba(148,187,233,0.9458158263305322) 100%);
}
 #fiat_deposite_active{
    width: 120px;
    height: 40px;
    font-size: 0.9rem;
    margin: 16px 25px 16px 0px;
    border: 1px solid #6c757d;
    border-radius: 50px;
    color: white;
    font-family: 'Spectral', serif;
    background-color: #01B0EA !important;
}
#fiat_deposite_active:hover{
    border-color: black;
}
.fiat_deposite_button button:hover{
    border-color: black;
}
.fiat{
    /* width: 600px; */
    height: 400px;
    border: 1px solid transparent;
    /* margin: 40px 427px 40px 427px; */
} 
.fiat label{
    padding-left: 17px;
    font-size: 1.4rem;
    font-family: 'Spectral', serif;
    margin-top: 10px;
}
.fiat input{
    margin-left: 17px;
    font-size: 1.2rem;
    background-color: transparent;
    border: 1px solid #6c757d;
    width: 295px;
    height: 50px;
    padding-left:10px;
    color: rgba(0, 0, 0, 0.87);

}
.fiat input:hover{
    border-color: black;
}
.fiat button{
    margin-left: 7px;
    font-size: 1rem;
    background-color: transparent;
    border: 1px solid #6c757d;
    width: 98px;
    height: 50px;
    margin-top: 0px;
    color: rgba(0, 0, 0, 0.87);
}
.fiat button:hover{
    border-color: black
}

.one_click_buy_login button{
    margin: 60px 60px 60px 137px;
    width: 150px;
    background-color: #01B0EA;
    color: #ffffff;
    font-size: 1.2rem;
    box-shadow: 10px 10px 14px -7px rgba(0,0,0,0.75);

}
.payment input{
    width: 398px;
}






@media only screen and (max-width: 768px){
     
    .fiat_deposite {
        margin: 50px 145px 30px 157px;
    }
    .fiat input {
        width: 330px;
        margin-left: 65px;
    }
    .fiat label{
        padding-left: 65px;
        
    }
    .payment input {
        width: 456px;
    }
    .fiat button{
        margin-right: 100px;
    }
    .one_click_buy_login button {
        margin: 50px -58px 0px 230px;
    
    }
    
}



@media only screen and (max-width: 640px){
    
    .fiat_deposite {
    margin: 50px 145px 30px 80px;
}

.fiat input {
    width: 258px;
    margin-left: 35px;
}
.fiat label{
    padding-left: 35px;
    
}
.fiat button{
    margin-right: 50px;
}
.payment input {
    width: 373px;
}
.one_click_buy_login button {
    margin: 50px -58px 0px 190px;

}
.lab{
    padding-left: 30px;
    
}

}







@media only screen and (max-width: 480px)
 {
    
    .fiat_deposite {
    margin: 50px 0px 30px 24px;
}
#fiat_deposite_active {
   
    margin: 16px 9px 16px 0px;
    font-size: 0.7rem;
    width: 100px;
}
.fiat_deposite_button button{
    margin: 16px 9px 16px 0px;
    font-size: 0.7rem;
    width: 100px;
}
.one_click_buy h1 {
    
    margin-right: 51px;
    margin-left: 60px;
}
.fiat input {
    width: 228px;
    margin-left: 5px;
}

.fiat label{
    padding-left: 5px;
    
}
.payment input {
    width: 314px;
    
}
.one_click_buy_login button {
        margin: 60px 0px 0px 130px;

}


/* .one_click_buy button {
    margin-right: 5px;
} */
.fiat button{
    margin-right: 28px;
    width:80px;
}

}



























