.corporate_hero h6{
  color: white;
  font-family: 'Spectral', serif;
  margin-top: 150px;
  font-size: 0.9rem;
}
.corporate_hero h2{
    color: white;
    font-family: 'Spectral', serif;
    margin-top: 20px;
    font-size: 3rem;
  }
  .corporate_hero button{
    border-radius: 10px;
    margin-bottom: 30px;
    background: linear-gradient(90deg, #BC35BC 0%, #124EE8 100%);
  }
  .corporate_hero button:hover{
        color: aqua !important;
  }

  .corporate_hero img{
    
    margin-top: 80px;
    margin-bottom: 30px;
  }
  .corporate1 h3{
    color: aqua;
    font-family: 'Spectral', serif;
    margin-top: 100px;
  }
  .corporate1 p{
    color: white;
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
    font-size: 1.2rem;
  }
  .corporate2 h3{
    color: white;
    font-family: 'Spectral', serif;
    margin-top: 30px;
    font-size: 1.5rem;
  }
  .corporate2 p{
    color: white;
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
    font-size: 1rem;
  }
  .corporate3 span{
    color: aqua;
    font-family: 'Spectral', serif;
    margin-top: 100px;
    font-size: 1.8rem;
  }
  .corporate3 h3{
    color: white;
    font-family: 'Spectral', serif;
    margin-top: 30px;
    font-size: 1.5rem;
  }
  .corporate3 p{
    color: white;
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
    font-size: 0.9rem;
  }
  .corporate4 span{
    color: white;
    font-family: 'Spectral', serif;
    margin-top: 10px;
    font-size: 1.2rem;
  }
  .corporate4 h1{
    color: aqua;
    font-family: 'Spectral', serif;
    margin-top: 100px;
    font-size: 1.8rem;
  }
  .corporate4 h3{
    color: white;
    font-family: 'Spectral', serif;
    margin-top: 30px;
    font-size: 1.5rem;
  }
  .corporate4 p{
    color: white;
    font-family: 'Open Sans', sans-serif;
    margin-top: 10px;
    font-size: 0.9rem;
    margin-bottom: 30px;
  }


















  @media only screen and (max-width: 640px){
    .corporate_hero h6{
        margin-top: 20px;
    }
    .corporate_hero h2{
        margin-top: 10px;
        font-size: 2.5rem;
    }
    .corporate_hero img{
        margin-top: 10px;
      }
  }
  @media only screen and (max-width: 768px){
    .corporate_hero h6{
        margin-top: 20px;
    }
    .corporate_hero h2{
        margin-top: 10px;
        font-size: 2.5rem;
    }
    .corporate_hero img{
        margin-top: 10px;
      }
  }